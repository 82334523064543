import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from './layout';

import './opening-hours.css';

export default function OpeningHours() {
  const data = useStaticQuery(graphql`
    query OpeningHoursQuery {
      wp {
        ...OpeningTimes
      }
    }
  `);
  const hours = data.wp.options.awwSettings.openingHours;
  return (
    <Layout title="Opening <span>hours</span>" className="footerHours">
      <ul>
        {hours.map((item, i) => {
          const key = `openingHours${i}`;
          const isActive = item.day !== null && item.time !== null;
          return (
            isActive && (
              <li
                key={key}
                style={{ ...itemStyle, marginTop: i !== 0 && `3px` }}
              >
                <strong>{item.day}:</strong>
                <span>{item.time}</span>
              </li>
            )
          );
        })}
      </ul>
    </Layout>
  );
}

const itemStyle = {
  display: `flex`,
  alignItems: `center`,
  justifyContent: `space-between`,
};
